var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "" },
              on: { getList: _vm.getList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("联系人/联系方式")]),
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入联系人/联系方式",
                    },
                    model: {
                      value: _vm.keywords,
                      callback: function ($$v) {
                        _vm.keywords = $$v
                      },
                      expression: "keywords",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    slot: "button-right",
                    size: "mini",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickFilter("add")
                    },
                  },
                  slot: "button-right",
                },
                [_vm._v(" 添加 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.commentList,
                "header-row-style": { background: "#d9dde1" },
                "header-cell-style": {
                  background: "#d9dde1",
                  color: "#0D0202",
                  fontSize: "12px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "联系人", prop: "receiverName" },
              }),
              _c("el-table-column", {
                attrs: { label: "联系方式", prop: "receiverMobile" },
              }),
              _c("el-table-column", {
                attrs: { label: "详细地址", prop: "address", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.provinceName +
                                row.cityName +
                                row.districtName +
                                row.address
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "发货默认", prop: "id" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isDefaultDelivery
                          ? _c("el-tag", [_vm._v(" 默认 ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "退货默认", prop: "question" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isDefault
                          ? _c("el-tag", [_vm._v(" 默认 ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row, "edit")
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addType === "add" ? "添加地址" : "编辑地址",
            visible: _vm.showFilter,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFilter = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "suggest-detail" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    "label-position": "left",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人:", prop: "realName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入联系人",
                          maxlength: "10",
                          size: "mini",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "realName", $$v)
                          },
                          expression: "form.realName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式:", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入联系方式",
                          maxlength: "20",
                          size: "mini",
                          type: "number",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地区:", prop: "question" } },
                    [
                      _c("SelectSite", {
                        attrs: {
                          "site-type": "edit",
                          "check-strictly": true,
                          "site-value": _vm.siteValue,
                        },
                        on: { input: _vm.changeStoreSite },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址:", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入详细地址",
                          type: "textarea",
                          maxlength: "200",
                          autosize: { minRows: 5, maxRows: 10 },
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "默认发货地址:",
                        prop: "isDefaultDelivery",
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.form.isDefaultDelivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isDefaultDelivery", $$v)
                          },
                          expression: "form.isDefaultDelivery",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "默认退货地址:", prop: "isDefault" } },
                    [
                      _c("el-switch", {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.form.isDefault,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isDefault", $$v)
                          },
                          expression: "form.isDefault",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" 提交 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showFilter = false
                            },
                          },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
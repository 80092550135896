<template>
  <div class="app-container">
    <div class="outer">
      <search-panel
        flex
        show-btn
        @getList="getList"
      >
        <div>
          <p>联系人/联系方式</p>
          <el-input
            v-model="keywords"
            size="mini"
            style="width: 240px"
            placeholder="请输入联系人/联系方式"
          />
        </div>
        <el-button
          slot="button-right"
          style="margin-left: 20px"
          size="mini"
          type="primary"
          @click="clickFilter('add')"
        >
          添加
        </el-button>
      </search-panel>
    </div>
    <div class="outer">
      <el-table
        v-loading="loading"
        :data="commentList"
        style="width: 100%"
        :header-row-style="{ background: '#d9dde1' }"
        :header-cell-style="{
          background: '#d9dde1',
          color: '#0D0202',
          fontSize: '12px'
        }"
      >
        <el-table-column
          label="联系人"
          prop="receiverName"
        />
        <el-table-column
          label="联系方式"
          prop="receiverMobile"
        />
        <el-table-column
          label="详细地址"
          prop="address"
          width="400"
        >
          <template slot-scope="{ row }">
            <span>{{ row.provinceName+row.cityName+row.districtName+row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="发货默认"
          prop="id"
        >
          <template slot-scope="{ row }">
            <el-tag v-if="row.isDefaultDelivery">
              默认
            </el-tag>
            <!-- <el-checkbox v-model="row.isDefaultDelivery" /> -->
          </template>
        </el-table-column>
        <el-table-column
          label="退货默认"
          prop="question"
        >
          <template slot-scope="{ row }">
            <el-tag v-if="row.isDefault">
              默认
            </el-tag>
            <!-- <el-checkbox v-model="row.isDefault" /> -->
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="200"
        >
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(row, 'edit')"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        v-if="commentList && commentList.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      /> -->
    </div>
    <el-dialog
      :title="addType === 'add' ? '添加地址' : '编辑地址'"
      :visible.sync="showFilter"
    >
      <div class="suggest-detail">
        <el-form
          ref="form"
          :model="form"
          label-width="110px"
          label-position="left"
          :rules="rules"
        >
          <el-form-item
            label="联系人:"
            prop="realName"
          >
            <el-input
              v-model="form.realName"
              placeholder="请输入联系人"
              maxlength="10"
              size="mini"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="联系方式:"
            prop="phone"
          >
            <el-input
              v-model="form.phone"
              placeholder="请输入联系方式"
              maxlength="20"
              size="mini"
              type="number"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="所在地区:"
            prop="question"
          >
            <SelectSite
              site-type="edit"
              :check-strictly="true"
              :site-value="siteValue"
              @input="changeStoreSite"
            />
          </el-form-item>
          <el-form-item
            label="详细地址:"
            prop="address"
          >
            <el-input
              v-model="form.address"
              size="mini"
              placeholder="请输入详细地址"
              type="textarea"
              maxlength="200"
              :autosize="{ minRows: 5, maxRows: 10 }"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="默认发货地址:"
            prop="isDefaultDelivery"
          >
            <el-switch
              v-model="form.isDefaultDelivery"
              size="mini"
            />
          </el-form-item>
          <el-form-item
            label="默认退货地址:"
            prop="isDefault"
          >
            <el-switch
              v-model="form.isDefault"
              size="mini"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="onSubmit"
            >
              提交
            </el-button>
            <el-button @click="showFilter = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SelectSite from '@/components/common/selectSite/index';

export default {
  name: 'address-management',
  components: {
    SelectSite,
  },
  data() {
    return {
      loading: false,
      keywords: '',
      addType: 'add',
      pagination: {
        currentPage: 0,
        pageSize: 10,
        count: 0,
      },
      commentList: [],
      showFilter: false, // 问题弹窗
      form: {
        realName: '', // 联系人
        phone: '', // 联系方式
        province: '',
        city: '',
        area: '',
        address: '', // 详细地址
        isDefaultDelivery: false, // 默认发货地址
        isDefault: false, // 默认退货地址
      },
      areaData: {},
      siteValue: [],
      rules: {
        realName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省市区', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
      },
      inputVisible: false,
      inputValue: '',
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 选择省市区
    changeStoreSite(e, value) {
      [this.form.province, this.form.city, this.form.area] = e;
      this.areaData = value.split('/');
    },
    getList() {
      this.loading = true;
      this.$axios
        .get(this.$api.address.list, {
          params: {
            searchName: this.keywords,
            currentPage: this.pagination.currentPage,
            pageSize: this.pagination.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.code === 0) {
            this.commentList = res.data.map((item) => {
              return {
                ...item,
                isDefaultDelivery: item.isDefaultDelivery === 1,
                isDefault: item.isDefault === 1,
              };
            });
            this.pagination.count = Number(res.data.total);
          } else {
            this.$message.error(res.msg);
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定删除该地址？', '删除地址', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .get(
              this.$api.address.delete,
              {
                params: {
                  id: row.id,
                },
              },
            )
            .then((res) => {
              if (res.code !== 0) return;
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.getList();
            });
        })
        .catch(() => {});
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.pagination.currentPage = 0;
      this.getList();
    },
    // 分页控制
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    // 新地址添加
    clickFilter() {
      this.addType = 'add';
      this.showFilter = true;
      this.form = {
        realName: '', // 联系人
        phone: '', // 联系方式
        province: '',
        city: '',
        area: '',
        address: '', // 详细地址
        isDefaultDelivery: false, // 默认发货地址
        isDefault: false, // 默认退货地址
      };
      this.$nextTick(() => {
        this.siteValue = [];
        this.siteValue = this.siteValue.concat([this.form.province,
          this.form.city, this.form.area]);
      });
    },
    // 编辑
    handleEdit(row) {
      this.addType = 'edit';
      this.showFilter = true;
      this.$nextTick(() => {
        this.siteValue = [];
        this.siteValue = this.siteValue.concat([row.provinceCode, row.cityCode, row.districtCode]);
        this.areaData = [row.provinceName, row.cityName, row.districtName];
        this.form = {
          realName: row.receiverName, // 联系人
          phone: row.receiverMobile, // 联系方式
          province: row.provinceCode,
          city: row.cityCode,
          area: row.districtCode,
          address: row.address, // 详细地址
          isDefaultDelivery: row.isDefaultDelivery, // 默认发货地址
          isDefault: row.isDefault, // 默认退货地址
          id: row.id,
        };
      });
    },
    // 提交
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        // 请选择省市区
        if (!this.form.province || !this.form.city || !this.form.area) {
          this.$message.error('请选择省市区');
          return false;
        }
        let data = {
          receiverName: this.form.realName,
          receiverMobile: this.form.phone,
          provinceCode: this.form.province,
          provinceName: this.areaData[0],
          cityCode: this.form.city,
          cityName: this.areaData[1],
          districtCode: this.form.area,
          districtName: this.areaData[2],
          address: this.form.address,
          isDefaultDelivery: this.form.isDefaultDelivery ? 1 : 0, // 默认发货地址
          isDefault: this.form.isDefault ? 1 : 0, //  默认退货地址
          id: this.addType === 'add' ? '' : this.form.id,
        };
        let url = this.addType === 'add' ? this.$api.address.add : this.$api.address.edit;
        this.$axios.post(url, data).then((res) => {
          if (res.code === 0) {
            if (this.addType === 'add') {
              this.$message.success('添加成功！');
            } else {
              this.$message.success('编辑成功！');
            }
            this.getList();
          }
        });
        this.showFilter = false;
        this.$refs.form.resetFields();
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
